import React, { useState } from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Button } from "react-bootstrap"
import RegistrationModal from "../components/RegistrationModal"
import useWindowSize from "../utils/useWindowSize"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { AiOutlineCaretRight } from "react-icons/ai"

export default function Retail_Hospitals() {
  const { width } = useWindowSize()
  const [isModalOpen, setModalStatus] = useState(false)

  function openModal() {
    setModalStatus(true)
  }

  function closeModal() {
    setModalStatus(false)
  }
  return (
    <Layout>
      <RegistrationModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        type="distributor"
      />
      <Container
        fluid
        style={{
          backgroundColor: "white",
          color: "black",
          paddingTop: "4rem",
          minHeight: `${width < 768 ? "50vh" : "90vh"}`,
        }}
      >
        <Seo
          title="Patameds has made it simple to sell to the healthcare industry"
          description="Position your product to the right audience, at the right time on the right media to drive profitable customer action. Learn more..."
          keywords="Pharmaceutical companies in kenya,Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain,List of pharmaceutical companies in kenya,Pharmaceutical wholesalers in Nairobi,Pharmaceutical distributors in kenya,Pharmaceutical distributors in Nairobi,companies looking for distributors in kenya,pharmaceutical importers in kenya, healthcare industry, pharmaceutical marketing, digital marketing medicine, online medicine marketplace, medicine advertisement"
          img="https://www.patameds.com/static/0e9b3c6ae328ea35fd63736b3bfd686b/5e32c/24.webp"
          siteUrl="https://www.patameds.com/distributor"
          canonical="https://www.patameds.com/distributor"
        ></Seo>
        <Row
          style={{
            paddingTop: `${width < 768 ? "10px" : "100px"}`,
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto" }}>
            <h1
              style={{
                color: "#03989e",
                fontWeight: "700",
                fontStyle: "normal",
              }}
            >
              Reduce time to market
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontStyle: "normal",
                textAlign: "justify",
                width: "80%",
              }}
            >
              Position your product to the right audience, at the right time on
              the right media to drive profitable customer action
            </p>

            <Button
              size="lg"
              style={{ backgroundColor: "#00A14B", marginBottom: "1rem" }}
              onClick={openModal}
            >
              Start Selling <AiOutlineCaretRight style={{ display: "inline" }} />
            </Button>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/24.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="reduce time to market"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#03989e",
          color: "white",
          minHeight: "100%",
        }}
      >
        <div
          style={{
            paddingTop: `${width < 768 ? "0px" : "20px"}`,
          }}
        >
          <Row style={{ padding: "10px" }}>
            <h2 style={{ margin: "auto" }}>What you get</h2>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/12.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="time to market"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>
                  lower time to market
                </p>
              </div>
              <div>
                <p>
                  Augment medical representatives capability with analytics and
                  engagement tools
                </p>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                {/* <Image src={pic13} fluid /> */}
                <StaticImage
                  src="../images/canva/13.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="clients"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>clients</p>
              </div>
              <div>
                <p>
                  Get online clients/orders from wholesalers within the platform
                  – increase revenue
                </p>
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/14.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="data driven decision"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>
                  data driven decisions
                </p>
              </div>
              <div>
                <p>
                  Customer purchase data/ wholesale purchase data analysis for
                  better decision-making in marketing, customer satisfaction,
                  and inventory management.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#03989e",
          color: "white",
          minHeight: "100%",
        }}
      >
        <Row
          style={{
            textAlign: "center",
            paddingTop: `${width < 768 ? "0px" : "40px"}`,
          }}
        >
          <Col xs={12} sm={4}>
            <div style={{ padding: "10px" }}>
              <StaticImage
                src="../images/canva/25.png"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="generate demand"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div>
              <p style={{ textTransform: "uppercase" }}>generate demand</p>
            </div>
            <div>
              <p>
                Advertise on the platform to all Healthcare workers by promoting
                your products – decreasing time to market
              </p>
            </div>
          </Col>

          <Col xs={12} sm={4}>
            <div style={{ padding: "10px" }}>
              <StaticImage
                src="../images/canva/26.png"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="expand your reach"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div>
              <p style={{ textTransform: "uppercase" }}>expand your reach</p>
            </div>
            <div>
              <p>
                Market through CMEs with CPDs to attract more healthcare workers
              </p>
            </div>
          </Col>

          <Col xs={12} sm={4}>
            <div style={{ padding: "10px" }}>
              <StaticImage
                src="../images/canva/15.png"
                layout="fullWidth"
                loading="eager"
                placeholder="tracedSVG"
                alt="save cost and time"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div>
              <p style={{ textTransform: "uppercase" }}>save cost & time</p>
            </div>
            <div>
              <p>
                We send emails to our email list to market your special offers
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "#03989e",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
          }}
        >
          <Col
            xs={12}
            sm={4}
            style={{ margin: "auto", backgroundColor: "#03989e" }}
          >
            <div style={{ color: "white" }}>
              <h2>Optimize your marketing</h2>
            </div>
            <div style={{ color: "white" }}>
              <p>
                Omnichannel marketing has a huge potential in pharma, the
                integration Patameds has put in place enables this kind of
                engagement with providers, a win-win for everyone.
              </p>
              <p>
                Leverage our Network to get more clients, Data to make better
                decisions in customer satisfaction, inventory management, and
                marketing; and Convenience to ease your operations
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/25.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="Optimize your marketing"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Trusted"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Industry Expertise</h4>
              <p>Patameds is managed by pharmaceutical experts</p>
            </div>
          </Col>

          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Convenient"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Easy Start</h4>
              <p>Convenient to sell with ease and with great terms</p>
            </div>
          </Col>

          <Col
            xs={12}
            sm={3}
            style={{
              backgroundColor: "#03989e5c",
              margin: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                margin: "20px 20px",
                alignItems: "center",
                paddingTop: "50px",
              }}
            >
              <div>
                <StaticImage
                  src="../images/canva/17.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="Risk Free"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <h4 style={{ margin: "20px" }}>Market Reach</h4>
              <p>
                Sell more to verified buyers and increase your revenue
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#03989e",
          color: "white",
          minHeight: `${width < 768 ? "50vh" : "100vh"}`,
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "50px"}`,
          }}
        >
          <Row
            style={{
              margin: "auto",
              backgroundColor: "#03989e",
              color: "white",
            }}
          >
            <h1>Marketing strategies we use to market Patameds</h1>
          </Row>

          <Row
            style={{
              paddingTop: "20px",
              margin: "20px",
              textAlign: "left",
              justifyContent: "space-around",
            }}
          >
            <Col xs={12} sm={4}>
              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>
                    Search engine optimization
                  </h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    Ensures our platform ranks at the top of the google search
                    when people search for medication.
                  </p>
                </div>
              </span>

              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>Content marketing</h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    We generate content for healthcare practitioner’s education
                    through blogging, videos with a definite objective like
                    encouraging them to visit the platform
                  </p>
                </div>
              </span>

              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>Social media marketing</h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    We generate content and share valuable information on social
                    media with a specific call to action.
                  </p>
                </div>
              </span>
            </Col>

            <Col xs={12} sm={4}>
              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>Email marketing</h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    We generate content and share valuable information on email
                    with a specific call to action.
                  </p>
                </div>
              </span>

              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>
                    Paid digital advertising.
                  </h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    We generate content and share through paid digital
                    advertisement to reach a wider target market
                  </p>
                </div>
              </span>

              <span>
                <div>
                  <h4 style={{ color: "#666769" }}>Tracking and Analytics</h4>
                </div>
                <div>
                  <p style={{ color: "white" }}>
                    We use analytics, i.e., google analytics, social media
                    analytics, e-commerce tools analytics to track the
                    performance of campaigns and restructure our marketing
                    strategy based on data to maximize ROI.
                  </p>
                </div>
              </span>
            </Col>
          </Row>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto", padding: "10px" }}>
            <div>
              <h4>
                Join other pharmaceutical distributors in embracing technology
                with Patameds
              </h4>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Button
                size="lg"
                style={{ backgroundColor: "#00A14B" }}
                onClick={openModal}
              >
                Start Selling <AiOutlineCaretRight style={{ display: "inline" }} />
              </Button>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            style={{
              padding: "10px",
              minHeight: "100%",
            }}
          >
            <StaticImage
              src="../images/canva/18.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="sign up"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
